/* Container for the PDF viewer */
#pdf-container {
  width: 100%;
  height: 100vh; /* Adjust as needed for your layout */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  padding: 10px; /* Optional: Adjust padding as needed */
  box-sizing: border-box; /* Ensure padding is included in height calculation */
  background-color: #f5f5f5; /* Optional: Set background color for better contrast */
}

/* Styling for the PDF document */
.pdf-container .react-pdf__Document {
  margin: 0 auto; /* Center the document horizontally */
  display: block; /* Ensure the document is a block element */
}

/* Styling for the individual pages */
.react-pdf__Page {
  margin: 0 auto; /* Center the page horizontally */
  display: block; /* Ensure pages are block elements */
  margin-bottom: 10px; /* Space between pages */
  max-width: 100%; /* Prevent pages from exceeding container width */
  height: auto; /* Maintain aspect ratio */
}

.pageNumberCount{
    text-align: center;
}