/* Custom styling for dropdown button */
.custom-dropdown .btn {
  background-color: white; /* Set background color to white */
  border: 1px solid #ced4da; /* Optional: Set a border color if needed */
  color: #495057; /* Optional: Set text color */
  box-shadow: none; /* Remove box-shadow for no outline effect */
}

/* Custom styling for dropdown menu */
.custom-dropdown .dropdown-menu {
  background-color: white; /* Set background color to white */
  border: 1px solid #ced4da; /* Optional: Border color for the dropdown menu */
}

/* Custom styling for dropdown items */
.custom-dropdown .dropdown-item {
  color: #495057; /* Text color for dropdown items */
}

/* Custom styling for dropdown item hover */
.custom-dropdown .dropdown-item:hover {
  background-color: #f8f9fa; /* Background color on hover */
  color: #212529; /* Text color on hover */
}

/* Ensure the dropdown toggle does not have any outline on focus */
.custom-dropdown .btn:focus {
  outline: none; /* Remove outline color */
  box-shadow: none; /* Remove box-shadow on focus */
}

/* Pagination Controls Container */
.pagination-controls {
  display: flex; /* Use Flexbox for alignment */
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Space out items between start and end */
  margin-bottom: 1rem; /* Margin for spacing */
}

/* Page Count Styling */
.pagination-controls p {
  margin: 0; /* Remove default margin */
  flex: 1; /* Allow the <p> element to grow and center itself */
  text-align: center; /* Center the text inside the <p> element */
}

/* Container for buttons */
.d-flex {
  display: flex; /* Enable Flexbox */
  align-items: center; /* Center items vertically */
  gap: 0.5rem; /* Adjust gap between buttons */
  flex-wrap: wrap; /* Allow buttons to wrap if needed */
}

/* General button styling */
.d-flex .btn {
  flex: 1; /* Allow buttons to expand and fill the space */
  margin: 0; /* Remove default margin */
}

#fileList{
  margin-bottom: 0.5em;
}

/* JobList.css */
.modal-backdrop-transparent {
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the alpha value for transparency */
}

.PENDING{
  background-color:#F8CBAD !important;
  color: #000000 !important;
}

.URGENT{
  background-color:#FF7F7F !important;
  color: #000000 !important;
}

.ERROR{
  background-color:#FFE699 !important;
  color: #000000 !important;
}

.NEW{
  background-color:#BDD7EE !important;
  color: #000000 !important;
}

.COMPLETED{
  background-color:#C6E0B4 !important;
  color: #000000 !important;
}

.REWORK{
  background-color:#D6B4FC !important;
  color: #000000 !important;
}

.CANCELLED{
  background-color:#C9C9C9 !important;
  color: #000000 !important;
}

/* Table Header Styling */
th.sortable {
  position: relative !important;
  text-align: left !important; /* Left-align the header text */
  padding: 12px 16px !important;
  cursor: pointer;
  height: 100%; /* Ensure the th has enough height */
}

th.normal-col{
  position: relative !important;
  text-align: left !important; /* Left-align the header text */
  padding: 12px 16px !important;
  height: 100%; /* Ensure the th has enough height */
}

th.sortable .d-flex {
  display: flex;
  align-items: center;  /* Vertically centers items */
  gap: 8px;             /* Ensures space between text and icon */
}

th.sortable .me-2 {
  font-weight: 500;
  font-size: 14px;
}

th.sortable .sort-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;
  border: none;
  background-color: transparent; /* Transparent background */
  transition: background-color 0.3s ease;
  transform: translateY(2px);  /* Move the icon down slightly */
  transform: rotate(180deg);
}

th.sortable .sort-icon:hover {
  background-color: transparent;
}

th.sortable .rotated .sort-svg {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

th .sort-svg {
  width: 100%;
  height: 100%;
  fill: #007bff;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

/* Apply hover effect on sort icon */
th.sortable .sort-icon:hover {
  background-color: transparent; /* Ensures background stays transparent on hover */
}

/* Table header hover effect */
th.sortable:hover {
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

/* Hide up/down arrows for number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari */
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
