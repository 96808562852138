/* FileViewer.css */

/* Container for the entire file viewer */
.file-preview-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 1000px; /* Adjust based on your design */
    margin: auto; /* Center horizontally */
    overflow: hidden; /* Prevent internal elements from causing overflow */
  }
  
  /* Header section with file name and download button */
  .file-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .file-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  
  .file-download {
    display: flex;
    align-items: center;
  }
  
  .download-button {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  
  .download-button svg {
    margin-left: 5px;
  }
  
  .file-preview {
    position: relative;
    width: 100%;
    height: 800px; /* Adjust based on your design */
    overflow: auto; /* Enable scrolling within this container */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  
  /* Handle image display */
  .file-img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
  
  /* Handle text display */
  .file-preview-text {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  .file-preview-text.error {
    color: #e74c3c;
  }
  
  /* Ensure iframe fits within the container and allows scrolling */
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block; /* Ensure it behaves like a block-level element */
  }
  