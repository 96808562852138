/* JobContainer.css */

/* Container for the entire ticket form and error components */
.ticket-container {
    display: flex;
    flex-direction: row; /* Align items in a row */
    gap: 20px; /* Optional: adds space between the form and error container */
    padding: 20px; /* Optional: adds padding around the container */
  }

  .dont-show-chat{
    flex: 10;
    max-width: 100%;
  }

  .show-chat{
    flex: 7; /* 60% of the available width */
    max-width: 70%; /* Ensures it does not exceed 60% */
  }

  /* Style for JobForm to take up 60% of the container width */
  .ticket-form-container {
    background-color: white;
    padding: 3em;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Style for JobError to take up 40% of the container width */
  .ticket-discussion-container {
    flex: 3; /* 30% of the available width */
    max-width: 30%; /* Ensures it does not exceed 30% */
  }

  .row .label {
    display: flex;  /* Align items in a row */
    justify-content: flex-start; /* Align items to the left (default) */
    align-items: center; /* Vertically align items in the center */
  }

  /* Optionally, you can add more styling for responsiveness and other adjustments */
  @media (max-width: 768px) {
    .ticket-container {
      flex-direction: column; /* Stack vertically on smaller screens */
    }

    .ticket-form-container,
    .ticket-discussion-container {
      max-width: 100%; /* Ensure full width on small screens */
    }
  }
  