/* General layout */
.dashboard {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Heading Styles */
  .dashboard h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .dashboard h2 {
    font-size: 1.8rem;
    margin-top: 20px;
  }

  .dashboard h3 {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  /* Form Styles */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
  }

  input[type="email"],
  input[type="date"],
  select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  input[type="email"]:focus,
  input[type="date"]:focus,
  select:focus {
    border-color: #4caf50;
    outline: none;
  }

  /* Disabled input style */
  input:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }

  /* Button Styles */
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }

  /* Error & Alert Styles */
  .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 10px;
  }

  /* Job Stats Styles */
  .job-stats {
    margin-top: 30px;
  }

  .job-stats p {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  /* Chart Styles */
  .chart-container {
    margin-top: 30px;
    max-width: 800px;
    margin: 0 auto;
  }

  /* Responsive Styles */
  @media (max-width: 600px) {
    .dashboard {
      padding: 15px;
    }

    .dashboard h1 {
      font-size: 2rem;
    }

    label {
      font-size: 0.9rem;
    }

    input[type="email"],
    input[type="date"],
    select {
      font-size: 0.9rem;
    }

    button {
      padding: 8px 16px;
    }
  }
