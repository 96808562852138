/* Chat Room Container */
.chat-room {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    width: 100%;
    max-width: 500px;
    max-height: auto; /* Set a maximum height */
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent overflow of content */
    height: 100%;
    background-color: white;
}

/* Chat Title */
.chat-room h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

/* Message Display Area */
.message-display {
    background-color: #e4e4e4;
    flex: 1; /* Allow this area to grow */
    max-height: 500px; /* Set a maximum height */
    overflow-x: hidden; /* Enable horizontal scrolling */
    overflow-y: auto; /* Hide vertical scrolling */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px; /* Optional for a more polished look */
    height: 100%;
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break properly */
}

/* Individual Messages */
.message {
    margin-bottom: 15px;
    position: relative;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 70%;
}

/* Message Timestamp */
.message strong {
    display: block;
    font-size: 12px;
    color: #999;
}

/* Sender Message */
.message.sender {
    background-color: #dcf8c6; /* Light green for sent messages */
    align-self: flex-end;
    margin-left: auto; /* Align to right */
}

/* Receiver Message */
.message.receiver {
    background-color: #ffffff; /* White for received messages */
    align-self: flex-start;
    margin-right: auto; /* Align to left */
}

/* Individual Messages */
.message-input {
    margin-bottom: 15px;
    position: relative;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 70%;
}

/* Message Timestamp */
.message-input strong {
    display: block;
    font-size: 12px;
    color: #999;
}

/* Input Area */
.input-area {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Space between messages and input area */
}
/* Container styling for the chat input area */
.form-control.chat-input-area {
    position: relative;
    width: 100%;
    min-height: 50px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center; /* Align children (message input and icon) on the same line */
    justify-content: space-between; /* Space between the input and icon */
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    overflow: hidden;
    margin: 5px 5px;
}

/* Styling for the message input area */
.message-input.area {
    margin-bottom: 0 !important;
    max-width: 100%;
    flex-grow: 1; /* Allow the input area to take up available space */
    min-height: 40px;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none; /* Remove default outline */
    overflow-y: auto; /* Enable scrolling if content overflows */
    white-space: pre-wrap; /* Allow text to wrap, including spaces */
    word-wrap: break-word; /* Break long words onto the next line */
}

/* Add a placeholder when message area is empty */
.message-input.area[contenteditable]:empty:before {
    content: "Type a message...";
    color: #888;
}

.message-input.area:focus {
    outline: 2px solid #4b9b9b; /* Or any other color to highlight focus */
    border-radius: 4px;
}

/* Styling for the emoji icon */
.icon {
    font-size: 20px;
    color: #888;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-left: 10px; /* Add spacing between the icon and the message area */
}

/* Hover effect for the emoji icon */
.icon:hover {
    color: #007bff; /* Change color on hover */
}

/* Styling when input is disabled (during file upload) */
.form-control.chat-input-area[disabled] .message-input.area {
    background-color: #e9ecef;
    color: #888;
    cursor: not-allowed;
}

.form-control.chat-input-area[disabled] .icon {
    color: #ccc;
}

/* Optional: Add focus effect for input area */
.form-control.chat-input-area:focus-within {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    border-color: #007bff;
}

/* Send Button */
.input-area button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background-color: #25d366; /* WhatsApp green */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Add transform for button effect */
}

/* Send Button Hover Effect */
.input-area button:hover {
    background-color: #1ebe54; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Mobile responsiveness: Ensure proper layout on small screens */
@media (max-width: 600px) {
    .form-control.chat-input-area {
        padding: 8px; /* Add padding if necessary */
    }
    .message-input .area {
        min-width: 0; /* Allow message area to shrink */
    }
    .icon {
        margin-left: 8px; /* Slightly reduce the space between the icon and the input area */
    }
}

/* Send Button */
.input-area button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background-color: #25d366; /* WhatsApp green */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Add transform for button effect */
}

/* Send Button Hover Effect */
.input-area button:hover {
    background-color: #1ebe54; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Style for the paperclip icon */
.fa-paperclip {
    margin-right: 5px;
    color: #888; /* Set a light grey color for the icon */
    font-size: 20px; /* Adjust the size of the paperclip */
    cursor: pointer; /* Change the cursor to indicate it's clickable */
}

/* Hover effect for the icon */
.fa-paperclip:hover {
    color: #007bff; /* Change color to blue on hover */
}

/* Focus effect to ensure the icon remains visible while typing */
.fa-paperclip:focus {
    color: #007bff; /* Change color to blue when the input is focused */
}


/* Responsive Styles */
@media (max-width: 500px) {
    .chat-room {
        width: 95%;
    }
}

/* Edit Button */
.edit-button {
    position: absolute; /* Position the button absolutely */
    top: 5px; /* Distance from the top of the message box */
    right: 5px; /* Distance from the right side of the message box */
    background-color: transparent; /* Transparent background */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
    color: #007bff; /* Bootstrap primary color */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.2s ease; /* Smooth transition */
}

/* Show edit button on message hover */
.message:hover .edit-button {
    opacity: 1; /* Show button on hover */
}

/* Edit Button Hover Effect */
.edit-button:hover {
    color: #0056b3; /* Darker blue on hover */
    text-decoration: underline; /* Underline on hover */
}

.read-status {
    font-size: 12px; /* Smaller font size for read status */
    position: absolute; /* Position relative to the message */
    bottom: 5px; /* Space from the bottom of the message */
    right: 10px; /* Space from the right of the message */
}

.read-status:before {
    display: inline-block;
    width: 10px; /* Width for the status indicator */
    height: 10px; /* Height for the status indicator */
    border-radius: 50%; /* Make it circular */
}

.read-status {
    color: #4caf50; /* Green for read messages */
}

.read-status:before {
    background-color: #4caf50; /* Green dot for read */
}

.unread {
    color: #f44336; /* Red for unread messages */
}

.unread:before {
    background-color: #f44336; /* Red dot for unread */
}
.edit-chat-box {
    display: flex;
    align-items: center; /* Center items vertically */
    margin-top: 10px; /* Space above the chat box */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    padding: 5px; /* Padding inside the box */
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.form-control-edit-msg {
    flex-grow: 1; /* Allow the input to grow */
    border: none; /* Remove default border */
    outline: none; /* Remove outline on focus */
    padding: 8px; /* Padding inside the input */
    font-size: 14px; /* Font size */
    border-radius: 5px; /* Rounded corners */
    background-color: #fff; /* White background for the input */
    margin-right: 5px; /* Space between input and button */
    transition: box-shadow 0.3s; /* Transition for shadow effect */
    max-height: 100px; /* Set a maximum height to trigger vertical scroll */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrolling */
    resize: none; /* Prevent manual resizing */
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break properly */
}

/* Add scrollbar styles for webkit browsers */
.form-control-edit-msg::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.form-control-edit-msg::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

.form-control-edit-msg::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker thumb on hover */
}

.form-control-edit-msg:focus {
    box-shadow: 0 0 5px rgba(66, 133, 244, 0.5); /* Shadow on focus */
}

.edit-chat-box button {
    background-color: #4caf50; /* Green background for button */
    color: white; /* White text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 6px 10px; /* Smaller padding for the button */
    font-size: 14px; /* Same font size as input */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for background */
}

.edit-chat-box button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.edit-chat-box button:disabled {
    background-color: #ccc; /* Gray background when disabled */
    cursor: not-allowed; /* Not-allowed cursor */
}

.cancel-edit {
    cursor: pointer; /* Change cursor to pointer on hover */
    color: #ff4d4d; /* Red color for the cancel button */
    font-weight: bold; /* Make text bold */
    font-size: 18px; /* Font size */
    margin-right: 10px; /* Space between cancel button and the input */
    transition: color 0.3s; /* Transition effect for color change */
}

.cancel-edit:hover {
    color: #ff1a1a; /* Darker red on hover */
}

.cancel-edit:focus {
    outline: none; /* Remove outline on focus */
}

/* Wrapper for the whole upload section */
.upload-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-family: Arial, sans-serif;
}

/* Styling for the list of uploaded files */
.uploaded-files {
    margin-top: 20px;
}

.uploading-files-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Individual msgfile being uploaded */
.uploading-msgfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

/* Hover effect for files */
.uploading-msgfile:hover {
    background-color: #f1f1f1;
}

/* File name styling */
.uploading-msgfile .msgfile-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.uploading-msgfile .msgfile-name {
    font-weight: bold;
    font-size: 14px;
    color: #333;
}

/* Spinner for uploading state */
.uploading-msgfile .uploading-status {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
}

.uploading-msgfile .uploading-status span {
    margin-left: 10px;
}

/* FontAwesome spinner styling */
.uploading-msgfile .uploading-status .fa-spinner {
    font-size: 16px;
    color: #007bff;
}

/* Styling for uploaded status message */
.uploading-msgfile .uploading-status span {
    font-size: 14px;
    color: #28a745;
}

/* Optional: error or failed upload status */
.uploading-msgfile .uploading-status span.error {
    color: #dc3545;
}

/* Button or icon for deleting a msgfile */
.uploading-msgfile .action-icon {
    cursor: pointer;
    color: #dc3545;
    font-size: 18px;
    transition: color 0.3s ease;
}

.uploading-msgfile .action-icon:hover {
    color: #ff5733;
}

/* Responsive Design: Ensure it adjusts for smaller screen sizes */
@media (max-width: 768px) {
    .uploading-msgfile {
        flex-direction: column;
        align-items: flex-start;
    }

    .uploading-msgfile .msgfile-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .uploading-msgfile .uploading-status {
        margin-top: 10px;
    }
}

/* Styling for the entire chat message containing files */
.msgfile-message {
    margin: 10px 0;
}

/* Image preview styling */
.msgfile-preview {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

/* Styling for msgfile links */
.msgfile-link {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

/* Styling for unread messages */
.read-status.unread {
    color: red;
}

/* Styling for read messages */
.read-status {
    font-size: 12px;
    color: gray;
    margin-top: 5px;
}

/* Styling for the uploaded msgfile display in the message */
.uploaded-files {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

/* Styling for the uploading files section */
.uploading-files-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.msgfile-message {
    margin-top: 15px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.msgfile-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
}

.msgfile-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    flex-grow: 1;
    word-wrap: break-word;   /* Ensures that long words are wrapped */
    overflow: hidden;        /* Hide overflowed text */
    text-overflow: ellipsis; /* Add ellipsis when text overflows */
    white-space: nowrap;     /* Prevent line breaks */
    display: inline-block;   /* Make the link an inline-block element */
    max-width: calc(100%); /* Ensure there's enough space for the download button */
}

.msgfile-link a:hover {
    text-decoration: underline;
}

.download-btn {
    background-color: #999;
    color: white;
    border: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-btn:hover {
    background-color: #999;
}

.download-btn:active {
    background-color: #999;
}

.msgfile-message .download-btn {
    margin-left: auto;
}

.msgfile-message .download-btn:focus {
    outline: none;
}

@media (max-width: 600px) {
    .msgfile-link {
        flex-direction: column;
        align-items: flex-start;
    }

    .msgfile-link a {
        margin-bottom: 5px;
        white-space: normal; /* Allow text to wrap in small screens */
        max-width: 100%;
    }
}

.input-area .picker {
    position: absolute;
    top: 270px; /* Default bottom position for large screens */
    right: 100px; /* Default right position */
    z-index: 1000;
    width: auto;
    max-width: 300px; /* Max width of the picker */
    transition: bottom 0.3s ease, top 0.3s ease; /* Smooth transition */
}

/* For medium screens like tablets */
@media (max-width: 1024px) {
    .input-area .picker {
        top: 310px; /* Adjust for better visibility */
        right: 70px;  /* Adjust right position */
    }
}

/* For small screens (portrait mode, phones) */
@media (max-width: 768px) {
    .input-area .picker {
        top: 62vh; /* Position it 5% from the bottom of the viewport */
        right: 20vw;  /* Position it 5% from the right */
        width: 90%;   /* Make picker width responsive */
    }
}

/* For very small screens (like mobile portrait) */
@media (max-width: 480px) {
    .input-area .picker {
        top: 75vh; /* Keep distance from the bottom, use viewport units */
        right: 20vw;   /* 5% from the right */
        width: 80%;    /* Adjust width for smaller screens */
    }
}

/* For extremely narrow screens */
@media (max-width: 360px) {
    .input-area .picker {
        top: 80vh; /* Move up a bit more */
        right: 110px;   /* Close to the right edge */
        width: 60%;
    }
}


.input-area .picker button{
    color: black !important;
    background-color: #f1f1f1 !important;
    font-size: 10px !important;
}