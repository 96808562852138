/* Header.css */

/* Navbar styling */
.navbar {
  background-color: #ffffff; /* White background */
  padding: 2rem;
  border-radius: 8px;
  position: relative; /* Make sure dropdown is positioned relative to the navbar */
}

.navbar-nav {
  white-space: nowrap; /* Prevent wrapping of nav items */
}

.nav-item {
  margin-right: 20px; /* Adds spacing between nav items */
}

.nav-item:last-child {
  margin-right: 0; /* Removes margin from the last item */
}

.navbar-brand img {
  height: 40px; /* Ensures consistent logo height */
}

/* Dropdown menu */
.navbar .dropdown-menu {
  position: absolute !important; /* Ensure dropdown is positioned absolutely */
  top: 100% !important; /* Position dropdown below the toggle button */
  left: 10 !important; /* Align dropdown to the left of the toggle button */
  z-index: 1050 !important; /* Ensure it is above other content */
  margin-top: 0.125rem !important; /* Adjust spacing if needed */
  min-width: 20rem !important; /* Adjust width if needed */
  max-height: 400px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; /* Optional: Add shadow for visibility */
}

/* Ensure nav-item is positioned relative to contain the dropdown */
.navbar-nav .nav-item {
  position: relative; /* Ensure dropdown is positioned relative to the nav item */
}

/* Badge for notification count */
.badge {
  background-color: red; /* Change this to your preferred color */
  color: white;
  border-radius: 12px;
  padding: 0.5em;
  margin-left: 0.5em; /* Space between icon and badge */
  position: absolute; /* Position the badge relative to the icon */
  top: 0;
  right: 0;
  transform: translate(50%, -50%); /* Center the badge on the icon */
}

/* Styling for dropdown headers */
.dropdown-header {
    font-weight: bold;
    font-size: 1.1rem; /* Slightly larger font size */
    padding: 0.5rem 1rem; /* Padding for spacing */
    background-color: #f8f9fa; /* Light background for contrast */
}

/* Styling for dropdown items */
.dropdown-item {
    transition: background-color 0.3s; /* Smooth background transition */
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
    background-color: #e2e6ea; /* Change background on hover */
}

/* Styling for read/unread alerts */
.text-muted {
    color: #6c757d; /* Muted text color for read alerts */
}

/* Alert item styling */
.alert {
  display: flex;
  flex-direction: column; /* Stack contents vertically */
  align-items: flex-start; /* Align items to the start */
  padding: 10px; /* Padding for spacing */
  margin: 5px 0 !important; /* Space between alerts */
  border-radius: 0 !important; /* Rounded corners */
  background-color: #f8f9fa; /* Light background color */
  border-block-color: black !important;
  color: #333; /* Default text color */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s; /* Smooth transition for hover */
  font-size: 16px; /* Smaller font size */
  font-style: serif;
  line-height: 1.4; /* Increase line height for readability */
  max-width: 100%; /* Limit the maximum width */
  white-space: pre-wrap !important; /* Allow text to wrap */
  word-wrap: break-word !important; /* Ensure long words break properly */
}

.alert:hover {
  background-color: #e2e6ea; /* Darker background on hover */
}

.alert.text-muted {
  color: #6c757d; /* Muted text color for read alerts */
}

/* Responsive styling */
@media (max-width: 576px) {
    .navbar .dropdown-menu {
        width: 100%; /* Full width on small screens */
    }
}

/* Optional: Add hover effects for alert items */
.dropdown-item.URGENT:hover {
  background-color: #f5c6cb; /* Darker red on hover */
}

.dropdown-item.ERROR:hover {
  background-color: #ffeeba; /* Darker yellow on hover */
}

.dropdown-item.COMPLETE:hover {
  background-color: #c3e6cb; /* Darker green on hover */
}

.dropdown-item.MESSAGE:hover {
  background-color: #b1afaf; /* Light green background */
}
