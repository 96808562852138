.file-card-container {
    height: 205px; /* Fixed height for cards */
    width: 100%; /* Ensure card uses the full width of its grid cell */
    border: 1px solid #ccc; /* Border around cards */
    border-radius: 8px; /* Rounded corners for cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for cards */
    display: flex;
    flex-direction: column;
    background-color: #fff; /* Background color for cards */
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
    padding: 0.2em ; /* Add padding inside the card */
    overflow: hidden; /* Prevent content from overflowing */
}

.file-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #1475cf; /* Dashed border for image container */
    height: 150px; /* Fixed height for image container */
    width: 100%; /* Ensure image container fills the card width */
    margin-bottom: 0.4em; /* Space between image and text */
    overflow: hidden; /* Hide overflow content */
}

.file-img {
    max-width: 100%; /* Ensure images fit within the container */
    height: auto; /* Maintain aspect ratio of images */
}

.file-item {
    list-style: none;
    margin: 0; /* Remove default margin */
    background-color: #f55e3038; /* Background color for file item */
    border-radius: 2px; /* Rounded corners for file item */
    display: flex;
    align-items: center; /* Center content vertically */
    padding: 0.5em 1em; /* Padding inside the file item */
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

.file-item svg {
    color: #f55321; /* Icon color */
}

.file-item svg:first-child {
    font-size: 1.2em; /* Font size for the first icon */
    margin-right: 0.8em; /* Space between icon and text */
}

.file-item p {
    flex: 1; /* Allow text to expand and fill available space */
    font-size: 0.9rem; /* Font size for text */
    margin: 0; /* Remove default margin */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
}

.file-item .actions {
    display: flex;
    align-items: center; /* Center actions vertically */
    margin-left: auto; /* Align actions to the right */
    cursor: pointer;
}

.file-item .actions .fa-spinner {
    font-size: 1.2em; /* Font size for spinner icon */
}
