/* Ensure the modal backdrop appears behind the modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1040; /* Ensure it's below the modal */
}

/* Modal container should be on top of the backdrop */
.modal {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1050; /* Ensure it's above the backdrop */
}

.modal.show {
  display: block; /* Show the modal when active */
}

/* Modal content */
.modal-dialog {
  position: relative;
  margin: 1.75rem auto;
  max-width: 500px; /* Adjust as needed */
}

.modal-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3rem;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.btn-close {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn-secondary, .btn-primary {
  cursor: pointer;
}
