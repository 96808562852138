.searchable-dropdown {
    position: relative;
    width: 100%;
  }
  
  .searchable-dropdown .form-control {
    width: 100%;
  }
  
  .searchable-dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    display: block;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 0;
    margin: 0;
  }
  
  .searchable-dropdown .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .searchable-dropdown .dropdown-item.active,
  .searchable-dropdown .dropdown-item:hover {
    background-color: #f0f0f0;
    color: #999;
  }
  
 /* SearchableDropdown.css */

.searchable-dropdown {
  position: relative;
  display: inline-block;
}

.form-control {
  width: 100%;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
}

.dropdown-search-area {
  padding-left: 30px !important; /* Space for the search icon */
  border: 3px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item.active {
  background-color: #d3d3d3;
}

.dropdown-item {
  border-bottom: 1px solid #eee;
}

.dropdown-item:last-child {
  border-bottom: none;
}
