/* ExcelViewer.css */
.excel-viewer {
    width: 100%;
    overflow: auto;
    border: 1px solid #ccc;
    margin-top: 20px;
  }
  
  .excel-viewer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .excel-viewer th, .excel-viewer td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .excel-viewer th {
    background-color: #f4f4f4;
  }
  
  .excel-viewer.error {
    color: red;
    font-weight: bold;
  }
  