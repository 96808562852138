body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pending-notifications-list {
  position: fixed; /* Sticks it to the viewport */
  right: 10px; /* Position it on the right side with a 10px margin */
  bottom: 0; /* Stick it to the bottom */
  z-index: 9999;
  background-color: transparent;
  padding: 10px;
  max-height: 600px;  /* You can adjust the max height if needed */
  max-width: 400px;   /* You can adjust the max width if needed */
  overflow-y: auto;   /* Makes the list scrollable if it overflows */
  border-radius: 5px;
}


.pending-notifications-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pending-notification-item {
  background-color: #F8CBAD !important;
  color: #000000 !important;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid black;  /* Adds a red border with 2px thickness */
}

.pending-notification-item p{
  margin: 0;
  padding: 0 5px;
}

.pending-notification-item button {
  background: rgb(255, 255, 255);        /* Black background */
  color: red;               /* Red text */
  border: none;             /* No border */
  font-size: 16px;          /* Font size */
  cursor: pointer;         /* Pointer cursor on hover */
  padding: 5px 10px;        /* Adds padding around the text */
  margin: 5px 5px;
  border-radius: 5px;       /* Rounded corners */
  display: flex;            /* Ensures button's content is centered */
  align-items: center;      /* Centers content vertically */
  justify-content: center;  /* Centers content horizontally */
}

.pending-notification-item button:hover {
  background: #444444;      /* Darker background color on hover */
}

