/* CommonPage.css */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f7f7;
  text-align: center;
}

.page-title {
  font-size: 6rem;
  font-weight: bold;
  color: #333;
}

.page-desc {
  font-size: 1.25rem;
  margin: 20px 0;
  color: #555;
}

.redirect-message {
  font-size: 1rem;
  color: #888;
  margin-bottom: 20px;
}

.go-home-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-home-btn:hover {
  background-color: #0056b3;
}
