.file-card-upload {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #edf2f7;
    max-width: 100%; /* Ensure it doesn't exceed the parent width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    width: 100%;
    min-height: 230px;
    max-height: 100%; /* Adjusted to ensure it doesn't exceed the viewport height */
    overflow: hidden; /* Prevent overflow */
}

.file-card-upload .file-inputs {
    position: relative;
    margin-bottom: 1.5em;
    width: 100%; /* Ensure it takes the full width */
}

.file-card-upload .file-inputs input {
    position: absolute;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    height: 46px;
    width: 100%; /* Ensure the input takes full width of the parent */
}

.file-card-upload .file-inputs button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #f55e30;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
}

.file-card-upload .file-inputs button i {
    width: 1.5em;
    height: 1.5em;
    padding: 0.4em;
    background-color: #fff;
    color: #f55e30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-size: 0.8em;
}

.file-card-upload .file-inputs:hover button {
    background-color: #f15120;
}

/* Responsive Design */
@media (max-width: 768px) {
    .file-card-upload {
        padding: 0.5em;
        min-height: 180px;
    }

    .file-card-upload .file-inputs {
        margin-bottom: 1em;
    }

    .file-card-upload .file-inputs button {
        font-size: 1rem;
    }

    .file-card-upload .file-inputs button i {
        width: 1.2em;
        height: 1.2em;
        font-size: 0.7em;
    }
}

@media (max-width: 480px) {
    .file-card-upload {
        padding: 0.5em;
        min-height: 150px;
    }

    .file-card-upload .file-inputs {
        margin-bottom: 0.5em;
    }

    .file-card-upload .file-inputs button {
        font-size: 0.9rem;
    }

    .file-card-upload .file-inputs button i {
        width: 1em;
        height: 1em;
        font-size: 0.6em;
    }
}

.main {
    font-weight: bold;
    margin-bottom: 0.4em;
}

.info {
    font-size: 0.8rem;
    text-align: center;
}

.error {
    color: #d9534f; /* Red color for error messages */
    background-color: #f8d7da; /* Light red background */
    border: 1px solid #d9534f; /* Red border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px; /* Padding for spacing */
    text-align: center; /* Center align the text */
    font-size: 0.9rem; /* Font size for readability */
    margin: 10px 0; /* Margin for spacing */
}

.upload-button {
    display: flex;
    align-items: center;
    background-color: #007bff; /* Example background color */
    color: white; /* Example text color */
    border: none; /* Remove default border */
    padding: 20px 20px; /* Add padding */
    border-radius: 5px; /* Optional: Add rounded corners */
    cursor: pointer; /* Change cursor on hover */
    position: relative !important;
}

.upload-button .icon {
    margin-right: 8px; /* Adjust gap between icon and text */
}